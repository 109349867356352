import React, { useEffect, useState } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBTable, MDBTableHead, MDBTableBody
  } from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";
const VPcreaturehistory = ({id}) => {

    const [purchasehistory, setPurchaseHistory] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);


    //purchase history
    useEffect(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventoryhistoryuseradmin?userid=${id}&type=Buy creature&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
            if(data.message == "success"){
                setIsLoading(false)
                setPurchaseHistory(data.data.history)
                setTotal(data.data.totalpages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
    },[page])

    const [ rate, setRate] = useState(0)
     // rate
     useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);


    return(
    <MDBCard>
      <div className=" flex w-full justify-between items-center p-4">
        <p>Purchased History</p>
        {/* <div className=" flex items-center justify-end gap-1">
          <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" className=" h-10 rounded-md p-2 bg-zinc-100"/>
          <MDBBtn color="warning" className=" mr-3" onClick={clear}>Clear</MDBBtn>
      </div> */}
      </div>
      <MDBCardBody>
        {/* <MDBCardTitle>Payin History</MDBCardTitle> */}
        <MDBTable small responsive>
        <MDBTableHead>
            <tr className="text-center">
            {/* <th scope='col'>Date</th> */}
            {/* <th scope='col'>Username</th> */}
            <th scope='col'>Tier</th>
            <th scope='col'>Creature</th>
            <th scope='col'>Date</th>
            {/* <th scope='col'>Process By</th> */}
            </tr>
        </MDBTableHead>
        <MDBTableBody>
            {
                purchasehistory.length !== 0 ?
                    purchasehistory.map((data, i) =>(
                    <tr key={i} className="text-center">
                        {/* <td scope='row'>{data.username}</td> */}
                        <td scope='row'>{data.rank === 'bronze' && 'Epic' || data.rank === 'silver' && 'Legend' || data.rank === 'gold' && 'Mythic'}</td>
                        <th scope='row'>{data.creaturename === 'Bronze Goblin' && 'Epic Goblin' || data.creaturename === 'Bronze Troll' && 'Epic Troll' || data.creaturename === 'Bronze Ogre' && 'Epic Ogre' || data.creaturename === 'Bronze Orc' && 'Epic orc' || data.creaturename === 'Silver Goblin' && 'Legend Goblin' || data.creaturename === 'Silver Troll' && 'Legend Troll' || data.creaturename === 'Silver Ogre' && 'Legend Ogre' || data.creaturename === 'Silver Orc' && 'Legend Orc' || data.creaturename === 'Silver Yeti' && 'Legend Yeti' || data.creaturename === 'Silver Minotaur' && 'Legend Minotaur' || data.creaturename === 'Silver Dragon' && 'Legend Dragon' || data.creaturename === 'Gold Orc' && 'Mythic Orc' || data.creaturename === 'Gold Yeti' && 'Mythic Yeti' || data.creaturename === 'Gold Minotaur' && 'Mythic Minotaur' || data.creaturename === 'Gold Dragon' && 'Mythic Dragon' || data.creaturename === 'Gold Leviathan' && 'Mythic Leviathan'}</th>
                        <th scope='row'>{new Date(data.createdAt).toLocaleString()}</th>
                    </tr>
                    ))
                :
                <tr className="text-center">
                  <th scope='row' colSpan={6}>No Data</th>
                </tr>
            }
            
        </MDBTableBody>
        </MDBTable>
        <PaginationPager
          total={total} 
          page={page} 
          setPage={setPage}
          isloading={isloading}
          />
      </MDBCardBody>
    </MDBCard>
    )
}

export default VPcreaturehistory;