/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";
import Tables from "../Tables";
import { Link, useLocation } from "react-router-dom";
import { Toast } from "components/Utils/Toast";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';
import CommissionHistory from "./Historytables/commission";
import GameWalletHistory from "./Historytables/gamewallet";
import FiatWalletHistory from "./Historytables/fiatwallet";
import ReferralHistory from "./Historytables/referral"
import Swal from "sweetalert2";
import CashinHistory from "./Cashinhistory/Cashinhistory";
import CashoutHistory from "./Cashouthistory/history";
import GameCashoutHistory from "./Cashouthistory/gamehistory";

function UserDashboard() {
  const [basicActive, setBasicActive] = useState('tab1');
  const [wallet, setWallet] = useState([])
  const [currentrank, setCurrentRank] = useState("0")
  const [id, setId] = useState("")
  const [tab, setTab] = useState('Cm')

  const [video, setVideo] = useState(false)


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/wallets/playerwallets`,{
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(result => result.json())
    .then(data => {
      if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
        Swal.fire({
          icon: "error",
          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if(ok.isConfirmed){
            window.location.href = "/login"
          }
        })
      }

      if(data.message == "success"){
        setWallet(data.data)
      } else if (data.message == "failed"){
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        })
      }
    })
  },[])

  useEffect(() => {
    
    fetch(`${process.env.REACT_APP_API_URL}/score/playerrank`,{
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(result => result.json())
    .then(data => {
      if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
        Swal.fire({
          icon: "error",
          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if(ok.isConfirmed){
            window.location.href = "/login"
          }
        })
      }

      if(data.message == "success"){
        setCurrentRank(data.data.rank)
      } else if (data.message == "failed"){
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        })
      }

    })
  },[])


  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };


    const kapy = (text) => {
        
    if(text){
        navigator.clipboard.writeText(text)
        Toast.fire({
            icon: 'success',
            title: 'Copy successfully'
        })
    } else {
        Toast.fire({
            icon: 'error',
            title: 'No text to copy'
        }) 
    }
    
  }

  useEffect(() => {
  
      fetch(`${process.env.REACT_APP_API_URL}/user/getreferrallink`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }
  
        if(data.message == "success"){
          setId(data.data)
        } else if (data.message == "failed"){
          Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
          })
        }
  
      })
    
    
  },[])


 
  const [referralStatus, setReferralStatus] = useState(false)

  useEffect(() => {
          fetch(`${process.env.REACT_APP_API_URL}/analytics/getreferrallinkstatus`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
              setReferralStatus(data.data.status)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

  const [walletReferral, setWalletReferral] = useState([])

      useEffect(() => {
          fetch(`${process.env.REACT_APP_API_URL}/wallethistory/getwalletstatistics`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setWalletReferral(data.data)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

  const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const downloadApk = () => {
    Swal.fire({
    title: "Do you want to download the apk?",
    showDenyButton: true,
    confirmButtonText: "Download",
    denyButtonText: `Cancel`
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location.href=""
    }
  })
  }

  const downloadApkMole = () => {
    Swal.fire({
    title: "Do you want to download the apk?",
    showDenyButton: true,
    confirmButtonText: "Download",
    denyButtonText: `Cancel`
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location.href=""
    }
  })
  }

  const playWeb = () => {
    Swal.fire({
    title: "Do you want to play in web?",
    showDenyButton: true,
    confirmButtonText: "Play now",
    denyButtonText: `Cancel`
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.open(`${process.env.REACT_APP_WEBGAME_URL}`, "_blank");
    }
  })
  }

  const playWebMole = () => {
    Swal.fire({
    title: "Do you want to play in web?",
    showDenyButton: true,
    confirmButtonText: "Play now",
    denyButtonText: `Cancel`
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.open(`https://whackattackwebgame.onrender.com`, "_blank");
    }
  })
  }

  const [eventCheck, setEventCheck] = useState('')
  const [unclaimed, setUnclaimed] = useState(0)
  const username = localStorage.getItem('username')
  const [waevent, setWaevent] = useState('')

  useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/maintenance/geteventmainte?maintenancetype=eventgame`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setEventCheck(data.data.value)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

   useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/maintenance/geteventmainte?maintenancetype=moleventgame`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setWaevent(data.data.value)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/inventory/getunclaimedincomeinventory`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setUnclaimed(data.data.totalaccumulated)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const close = () => {
    localStorage.setItem('video','false')
    window.location.reload()
  }

  const [videoPopup, setVideopopup] = useState('true')
  const location = useLocation();
  useEffect(() => {
    const videoState = localStorage.getItem('video')
    setVideopopup(videoState)
   
  },[])

  return (
    <>
    {videoPopup === 'true' && (
      <div className=" z-[999999] absolute bg-zinc-950/70 w-full h-screen flex items-center justify-center">

        <div className=" relative w-full h-full flex items-center justify-center">
            <button onClick={close} className=" text-zinc-950 font-semibold cursor-pointer bg-zinc-100 px-6 py-2 rounded-full absolute top-10 right-10">X Close</button>
            <iframe className=" w-[90%] md:w-[70%] aspect-video " src="https://www.youtube.com/embed/PGBiZI8N7d8?si=FOvEHv88O06AG8QQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

      </div>
    )}
    
      <div className={`content ${videoPopup === 'true' && ' overflow-hidden'}`}>

        <Row className=" relative h-[165px]" style={{display:'flex',alignItems:'center',justifyContent:'space-between',backgroundColor:'white',marginBottom:'1.5rem',padding:'1.5rem', borderRadius:'.5em',flex:'wrap',backgroundImage:"url('/dbtop.png')",backgroundPosition:'center'}}>
        {/* {eventCheck !== '0' && (
          <Col style={{display:'flex',gap:'2rem'}}>
            <div className="icon-big text-center icon-warning">
              <i className="nc-icon nc-chart-bar-32 text-white" style={{fontSize:'7rem'}} />
            </div>

            <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
            <h2 style={{margin:'0',fontSize:'4rem',color:'white'}}>{currentrank}</h2>
            <p style={{margin:'0',fontSize:'1em',color:'white'}}>Current Rank</p>

            </div>
                
                 
                 
          </Col>
        )} */}
        <Col>
        <img src="/TGIF Logo.png" className=" hidden lg:block absolute w-[200px] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"/>
        <h2 className=" text-4xl font-semibold text-white">{username}</h2>
        <p className=" text-sm font-semibold text-zinc-200 mt-1">Creature Smash Event: <span className={`${eventCheck === '1' ? ' text-green-500' : ' text-red-500'}`}>{eventCheck === '1' ? 'Ongoing' : 'No event'}</span></p>
        <p className=" text-sm font-semibold text-zinc-200 mt-1">Whack Attack Event: <span className={`${waevent === '1' ? ' text-green-500' : ' text-red-500'}`}>{waevent === '1' ? 'Ongoing' : 'No event'}</span></p>

        </Col>
          

          {/* <img src="/Creature Smash Logo.png" style={{width:'150px'}}/> */}
          <Col lg='2'>
           {referralStatus === true && (
           <MDBBtn onClick={() => kapy(`${process.env.REACT_APP_WEBSITE_URL}/register?id=${id}`)}>Copy Referral Link</MDBBtn>
           )}
          </Col>

          
           
        </Row>
       

        <Row>

          <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-1 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-credit-card text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Load Balance</p>
                      <CardTitle tag="p" className=" text-3xl font-medium">
                      ₱ {wallet.fiatbalance?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                       <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                      $ {(wallet.fiatbalance / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                 
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-1 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bank text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Withdrawables</p>
                       <CardTitle tag="p" className=" text-3xl font-medium">₱ {(wallet.gamebalance + wallet.commissionbalance).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}</CardTitle>
                        <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                      $ {((wallet.gamebalance + wallet.commissionbalance) / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
             
            </Card>
          </Col>

           <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-1 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-app text-secondary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Game Total Earning</p>
                      <CardTitle tag="p" className=" text-3xl font-medium">₱ {walletReferral.game?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}</CardTitle>
                        <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                      $ {(walletReferral.game / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter> */}
            </Card>
          </Col>

           <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-1 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5" >
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Game Wallet Earnings </p>
                      <p className=" card-category">(₱ {unclaimed.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })})</p>
                      <CardTitle tag="p" className=" text-3xl font-medium">
                      ₱ {wallet.gamebalance?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                       <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                      $ {(wallet.gamebalance / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          {/* <Col lg="4" md="6" sm="6" >
            <Card className="card-stats" style={{height:'87%'}}>
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-layout-11 text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Current Rank</p>
                      <CardTitle tag="p">{currentrank}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            
            </Card>
          </Col> */}

           <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-2 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-02" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Referral Total Commission</p>
                       <CardTitle tag="p" className=" text-3xl font-medium">₱ {walletReferral.referral?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}</CardTitle>
                        <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                        $ {(walletReferral.referral / rate).toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })} 
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-2 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-badge text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Unilevel Total Commission</p>
                      <CardTitle tag="p" className=" text-3xl font-medium">₱ {walletReferral.unilevel?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })} </CardTitle>
                        <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                        $ {(walletReferral.unilevel / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })} 
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-2 h-full" >
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Commission Wallet Earnings</p>
                      <CardTitle tag="p" className=" text-3xl font-medium">
                      ₱ {wallet.commissionbalance?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                      $ {(wallet.commissionbalance / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-calendar" /> Last day
                </div>
              </CardFooter> */}
            </Card>
          </Col>

           <Col lg="3" md="6" sm="6" className=" mb-4">
            <Card className="card-stats py-2 h-full">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-credit-card text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Earnings</p>
                      <CardTitle tag="p" className=" text-3xl font-medium">₱ {(walletReferral.unilevel + walletReferral.referral + walletReferral.game).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })} </CardTitle>
                        <CardTitle tag="p" style={{color:'dimgrey',fontSize:'1rem'}}>
                        $ {((walletReferral.unilevel + walletReferral.referral + walletReferral.game) / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })} 
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter> */}
            </Card>
          </Col>

        </Row>

         <select        
              value={basicActive}
              name="paymentmethod" 
              className=" mb-4 px-4 py-[8px] rounded-md lg:hidden" 
              onChange={(e) => setBasicActive(e.target.value)}
              style={{width: "100%"}}>
                  <option value='tab1'>Load Balance History</option>
                  <option value='tab3'>Commission History (Lvl 2-10)</option>
                  <option value='tab4'>Referral History (Lvl 1)</option>
                  <option value='tab2'>Game Wallet Earning History</option>
                  <option value='tab5'>Commission Withdraw History</option>
                  <option value='tab6'>Game Withdraw History</option>
              </select>
        
        <MDBTabs className=' hidden lg:flex mb-3 w-full overflow-x-auto mt-10' pills>
        <MDBTabsItem className=" ">
          <MDBTabsLink className=" " onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
          <MDBIcon fas icon='credit-card' className='me-2 text-center' />Load Balance History
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
          <MDBIcon fas icon='hand-holding-usd' className='me-2' /> Commission History (Lvl 2-10)
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
          <MDBIcon fas icon='users' className='me-2' />Referral History (Lvl 1)
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
          <MDBIcon fas icon='wallet' className='me-2' />Game Wallet Earning History
          </MDBTabsLink>
        </MDBTabsItem>

         <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab5')} active={basicActive === 'tab5'}>
          <MDBIcon fas icon='wallet' className='me-2' />Commission Withdraw History
          </MDBTabsLink>
        </MDBTabsItem>

          <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab6')} active={basicActive === 'tab6'}>
          <MDBIcon fas icon='wallet' className='me-2' />Game Withdraw History
          </MDBTabsLink>
        </MDBTabsItem>
     
      

        {/* <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab5')} active={basicActive === 'tab5'}>
          <MDBIcon fas icon='users' className='me-2' />Cashin History
          </MDBTabsLink>
        </MDBTabsItem> */}
      </MDBTabs>

      <Row style={{gap:'1rem'}}>
        <Col lg="6" md="6" sm="6">
          <MDBTabsContent >
          <MDBTabsPane open={basicActive === 'tab1'}>
          <FiatWalletHistory/>
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab2'}> 
          <GameWalletHistory/>
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab3'}>
          <CommissionHistory/>
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab4'}>
          <ReferralHistory/>
          </MDBTabsPane>

          <MDBTabsPane open={basicActive === 'tab5'}>
            <CashoutHistory/>
          </MDBTabsPane>

           <MDBTabsPane open={basicActive === 'tab6'}>
            <GameCashoutHistory/>
          </MDBTabsPane>

           {/* <MDBTabsPane open={basicActive === 'tab5'}>
          <CashinHistory/>
          </MDBTabsPane> */}
        </MDBTabsContent>
        </Col>

        <Col style={{backgroundColor:'white',padding:'1rem'}}>
        <p style={{fontSize:'1.8rem',width:'100%',textAlign:'center'}}>DOWNLOAD NOW</p>

        <div className=" w-full flex items-center justify-center gap-2 my-6">
          <button onClick={() => setTab('Cm')} className={`px-6 py-2 text-sm font-semibold ${tab === 'Cm' ? 'bg-blue-700/80 text-white' : 'bg-zinc-200 text-black'} rounded-md`}>Creature Smash</button>
          <button onClick={() => setTab('Wa')} className={`px-6 py-2 text-sm font-semibold ${tab === 'Wa' ? 'bg-blue-700/80 text-white' : 'bg-zinc-200 text-black'} rounded-md`}>Whack Attack</button>

        </div>
        {tab === 'Cm' && (
           <Row>
           <Col lg="6" md="6" sm="6">
            <Card className="" style={{height:'auto'}}>
              <CardBody style={{height:'200px'}}>
                <Row>
                 
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                  
                    <div className="icon-big text-center icon-warning">
                      {/* <i className="nc-icon nc-mobile text-primary" style={{fontSize:'4rem'}} /> */}
                      <img src="/android.png"/>
                    </div>
                 
                      <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Download Apk</p>
                      <a >
                        <MDBBtn onClick={downloadApk} >Download Now</MDBBtn>
                      </a>
                      <p />
                  </div>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="6" md="6" sm="6">
            <Card className="card-stats" style={{height:'200px'}}>
              <CardBody>
                <Row>
                   <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                  
                    <div className=" text-center icon-warning" >
                      {/* <i className="nc-icon nc-globe text-primary" style={{fontSize:'4rem'}}/> */}
                      <img src="/internet.png"/>

                    </div>
                 
                     <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Play on web</p>
                      <a >
                        <MDBBtn onClick={playWeb}>Play Now</MDBBtn>
                      </a>
                      <p />
                  </div>
                 
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter> */}
            </Card>
          </Col>

           <Col lg="6" md="6" sm="6">
            <Card className="card-stats" style={{height:'200px'}}>
              <CardBody>
                <Row>
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                  
                    <div className=" text-center icon-warning" >
                      {/* <i className="nc-icon nc-mobile text-primary" style={{fontSize:'4rem'}}/> */}
                      <img src="/playstore.png" width={60}/>

                    </div>
                 
                     <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Playstore</p>
                      <a >
                        <MDBBtn disabled>Comin Soon!</MDBBtn>
                      </a>
                      <p />
                  </div>
                 
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter> */}
            </Card>
          </Col>
           <Col lg="6" md="6" sm="6">
            <Card className="card-stats" style={{height:'200px'}}>
              <CardBody>
                <Row>
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                  
                    <div className=" text-center icon-warning" >
                      {/* <i className="nc-icon nc-mobile text-primary" style={{fontSize:'4rem'}}/> */}
                      <img src="/social.png" width={60}/>

                    </div>
                 
                     <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Appstore</p>
                      <a >
                        <MDBBtn disabled>Comin Soon!</MDBBtn>
                      </a>
                      <p />
                  </div>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          </Row>
        )}

        {tab === 'Wa' && (
          <Row>
          <Col lg="6" md="6" sm="6">
           <Card className="" style={{height:'auto'}}>
             <CardBody style={{height:'200px'}}>
               <Row>
                
                 <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                 
                   <div className="icon-big text-center icon-warning">
                    
                     <img src="/android.png"/>
                   </div>
                
                     <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Download Apk</p>
                     <a >
                       <MDBBtn>Download Now</MDBBtn>
                     </a>
                     <p />
                 </div>
               </Row>
             </CardBody>
           
           </Card>
         </Col>

         <Col lg="6" md="6" sm="6">
           <Card className="card-stats" style={{height:'200px'}}>
             <CardBody>
               <Row>
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                 
                   <div className=" text-center icon-warning" >
                     
                     <img src="/internet.png"/>

                   </div>
                
                    <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Play on web</p>
                     <a >
                       <MDBBtn>Play Now</MDBBtn>
                     </a>
                     <p />
                 </div>
                
               </Row>
             </CardBody>
           
           </Card>
         </Col>

          <Col lg="6" md="6" sm="6">
           <Card className="card-stats" style={{height:'200px'}}>
             <CardBody>
               <Row>
                 <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                 
                   <div className=" text-center icon-warning" >
                     
                     <img src="/playstore.png" width={60}/>

                   </div>
                
                    <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Playstore</p>
                     <a >
                       <MDBBtn disabled>Comin Soon!</MDBBtn>
                     </a>
                     <p />
                 </div>
                
               </Row>
             </CardBody>
             
           </Card>
         </Col>

          <Col lg="6" md="6" sm="6">
           <Card className="card-stats" style={{height:'200px'}}>
             <CardBody>
               <Row>
                 <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0',gap:'.5rem'}}>
                 
                   <div className=" text-center icon-warning" >
                    
                     <img src="/social.png" width={60}/>

                   </div>
                
                    <p className="card-category" style={{fontSize:'1.5rem',margin:'0'}}>Appstore</p>
                     <a >
                       <MDBBtn disabled>Comin Soon!</MDBBtn>
                     </a>
                     <p />
                 </div>
               </Row>
             </CardBody>
            
           </Card>
         </Col>
         
         </Row>

        )}
       
        </Col>
        
      </Row>

      
      </div>
    </>
  );
}

export default UserDashboard;
