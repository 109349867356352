/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Button
} from "reactstrap";
import Swal from "sweetalert2";
import { MDBSpinner,MDBContainer, MDBRow } from 'mdb-react-ui-kit'


import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane, MDBIcon
  } from 'mdb-react-ui-kit';
// core components
import Tables from "../Tables";
import CreatureCard from "components/Cards/Creature";
import MoleCard from "components/Cards/Mole";
import CreatureCardLocked from "components/Cards/CreatureLocked";
import bronzegoblin from "assets/01-Bronze/01-Goblin.png"
import bronzetroll from "assets/01-Bronze/02-Troll.png"
import bronzeogre from "assets/01-Bronze/03-Ogre.png"
import bronzeorc from "assets/01-Bronze/04-Orc.png"

import silvergoblin from "assets/02-Silver/01-Goblin.png"
import silvertroll from "assets/02-Silver/02-Troll.png"
import silverogre from "assets/02-Silver/03-Ogre.png"
import silverorc from "assets/02-Silver/04-Orc.png"
import silveryeti from "assets/02-Silver/05-Yeti.png"
import silverminotaur from "assets/02-Silver/06-Minotaur.png"
import silverdragon from "assets/02-Silver/07-Dragon.png"

import goldorc from "assets/03-Gold/01-Orc.png"
import goldyeti from "assets/03-Gold/02-Yeti.png"
import goldminotaur from "assets/03-Gold/03-Minotaur.png"
import golddragon from "assets/03-Gold/04-Dragon.png"
import goldleviathan from "assets/03-Gold/05-Leviathan.png"


//wa
import wood1 from "assets/Whack Attack Game Tab/1 - Wood Tier/1 - normal Mole.png"
import wood2 from "assets/Whack Attack Game Tab/1 - Wood Tier/2 - bucket mole.png"
import wood3 from "assets/Whack Attack Game Tab/1 - Wood Tier/3 - cone mole.png"
import wood4 from "assets/Whack Attack Game Tab/1 - Wood Tier/4 - Construction Mole.png"
import wood5 from "assets/Whack Attack Game Tab/1 - Wood Tier/5 - Miner Mole.png"

import football from "assets/Whack Attack Game Tab/2 - Stone Tier/1 - Football player Mole.png"
import soldier from "assets/Whack Attack Game Tab/2 - Stone Tier/2 - Soldier Mole.png"
import general from "assets/Whack Attack Game Tab/2 - Stone Tier/3 - General Mole.png"
import explorer from "assets/Whack Attack Game Tab/2 - Stone Tier/4 - Explorer Mole.png"
import marchingband from "assets/Whack Attack Game Tab/2 - Stone Tier/5 - Marching Band Mole.png"

import viking from "assets/Whack Attack Game Tab/3 - Viking Tier/1 - viking mole.png"
import shogun from "assets/Whack Attack Game Tab/3 - Viking Tier/2 - Shogun mole.png"
import samurai from "assets/Whack Attack Game Tab/3 - Viking Tier/3 - Samurai Mole.png"
import paroah from "assets/Whack Attack Game Tab/3 - Viking Tier/4 - Pharaoh Mole.png"
import king from "assets/Whack Attack Game Tab/3 - Viking Tier/5 - King Mole.png"



import InventoryHistory from "./Historytables/iventory";
import MoleBuyHistory from "./Historytables/Molebuyhistory";

function Buycreature() {
    const [basicActive, setBasicActive] = useState('tab1');
    const [basicActive2, setBasicActive2] = useState('tab1');
    const [isloading, setIsLoading] = useState(true);
    const [ price, setPrice] = useState([])
    const [tab, setTab] = useState('Cm')

    const [molepricelist, setMolepricelist] = useState([])
    


  
    const handleBasicClick = (value) => {
      if (value === basicActive) {
        return;
      }
  
      setBasicActive(value);
    };

    const handleBasicClick2 = (value) => {
      if (value === basicActive2) {
        return;
      }
  
      setBasicActive2(value);
    };

      useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getpricelist?type=bronze`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
               setPrice(data.data)
               setIsLoading(false)
              // console.log('price', data.data)

            } else if (data.message == "failed"){
               setIsLoading(false)
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

     useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getmolepricelist?type=wood`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
               setMolepricelist(data.data)
               setIsLoading(false)
              console.log('mole price', data.data)

            } else if (data.message == "failed"){
               setIsLoading(false)
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

     const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);


    
  return (
    <>

     

      { isloading ? (
        <div className="content"
        style={{display:'flex', alignItems:'center', justifyContent:'center',height:'100%', width:'100%'}}
        >
            <MDBSpinner size="sm"/>
        </div>
      ) : (
        <>
          <div className="content">
             <div className=" flex items-center gap-2 my-6">
              <button onClick={() => setTab('Cm')} className={`px-6 py-2 text-sm lg:text-lg font-semibold ${tab === 'Cm' ? 'bg-blue-700/80 text-white' : 'bg-zinc-200 text-black'} rounded-md`}>Creature Smash</button>
              <button onClick={() => setTab('Wa')} className={`px-6 py-2 text-sm lg:text-lg font-semibold ${tab === 'Wa' ? 'bg-blue-700/80 text-white' : 'bg-zinc-200 text-black'} rounded-md`}>Whack Attack</button>

            </div>

            {(tab === 'Cm' && price.length !== 0) && (
            <>
            <MDBTabs className='mb-3' pills>
            <MDBTabsItem>
              <MDBTabsLink style={{fontWeight:'bold'}} onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                Epic
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink style={{fontWeight:'bold'}} onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                Legend
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink style={{fontWeight:'bold'}} onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                Mythic
              </MDBTabsLink>
            </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent style={{marginBottom:'2rem'}}>
            <MDBTabsPane open={basicActive === 'tab1'}>
            <MDBRow style={{gap:'1rem'}}>
                <CreatureCard locked={price.bronze.b_goblin.locked} type="b_goblin" image={bronzegoblin} title="Epic Goblin" amount={`${price.bronze.b_goblin.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.bronze.b_goblin.expiration} days ${price.bronze.b_goblin.percentage * 100}%`} usd={`${(price.bronze.b_goblin.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.bronze.b_troll.locked} type="b_troll" image={bronzetroll} title="Epic Troll" amount={`${price.bronze.b_troll.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.bronze.b_troll.expiration} days ${price.bronze.b_troll.percentage * 100}%`} usd={`${(price.bronze.b_troll.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.bronze.b_ogre.locked} type="b_ogre" image={bronzeogre} title="Epic Ogre" amount={`${price.bronze.b_ogre.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.bronze.b_ogre.expiration} days ${price.bronze.b_ogre.percentage * 100}%`}  usd={`${(price.bronze.b_ogre.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.bronze.b_orc.locked} type="b_orc" image={bronzeorc} title="Epic Orc" amount={`${price.bronze.b_orc.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.bronze.b_orc.expiration} days ${price.bronze.b_orc.percentage * 100}%`}  usd={`${(price.bronze.b_orc.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>
            </MDBRow>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}> 
            <Row style={{gap:'1rem'}}>
                <CreatureCard locked={price.silver.s_goblin.locked} type="s_goblin" image={silvergoblin} title="Legend Goblin" amount={`${price.silver.s_goblin.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} description={`${price.silver.s_goblin.expiration} days ${price.silver.s_goblin.percentage * 100}%`} usd={`${(price.silver.s_goblin.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.silver.s_troll.locked} type="s_troll" image={silvertroll} title="Legend Troll" amount={`${price.silver.s_troll.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} description={`${price.silver.s_troll.expiration} days ${price.silver.s_troll.percentage * 100}%`}  usd={`${(price.silver.s_troll.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.silver.s_ogre.locked} type="s_ogre" image={silverogre} title="Legend Ogre" amount={`${price.silver.s_ogre.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} description={`${price.silver.s_ogre.expiration} days ${price.silver.s_ogre.percentage * 100}%`}  usd={`${(price.silver.s_ogre.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.silver.s_orc.locked} type="s_orc" image={silverorc} title="Legend Orc" amount={`${price.silver.s_orc.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} description={`${price.silver.s_orc.expiration} days ${price.silver.s_orc.percentage * 100}%`}  usd={`${(price.silver.s_orc.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.silver.s_yeti.locked} type="s_yeti" image={silveryeti} title="Legend Yeti" amount={`${price.silver.s_yeti.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.silver.s_yeti.expiration} days ${price.silver.s_yeti.percentage * 100}%`}  usd={`${(price.silver.s_yeti.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.silver.s_minotaur.locked} type="s_minotaur" image={silverminotaur} title="Legend Minotaur" amount={`${price.silver.s_minotaur.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.silver.s_minotaur.expiration} days ${price.silver.s_minotaur.percentage * 100}%`} usd={`${(price.silver.s_minotaur.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.silver.s_dragon.locked} type="s_dragon" image={silverdragon} title="Legend Dragon" amount={`${price.silver.s_dragon.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.silver.s_dragon.expiration} days ${price.silver.s_dragon.percentage * 100}%`}  usd={`${(price.silver.s_dragon.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>
            </Row>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab3'}>
            <Row style={{gap:'1rem'}}>
                <CreatureCard locked={price.gold.g_orc.locked} type="g_orc" image={goldorc} title="Mythic Orc"  amount={`${price.gold.g_orc.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}  `} description={`${price.gold.g_orc.expiration} days ${price.gold.g_orc.percentage * 100}%`} usd={`${(price.gold.g_orc.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.gold.g_yeti.locked}  type="g_yeti" image={goldyeti} title="Mythic Yeti" amount={`${price.gold.g_yeti.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.gold.g_yeti.expiration} days ${price.gold.g_yeti.percentage * 100}%`}  usd={`${(price.gold.g_yeti.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.gold.g_minotaur.locked}  type="g_minotaur" image={goldminotaur} title="Mythic Minotaur" amount={`${price.gold.g_minotaur.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.gold.g_minotaur.expiration} days ${price.gold.g_minotaur.percentage * 100}%`}  usd={`${(price.gold.g_minotaur.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>
                
                <CreatureCard locked={price.gold.g_dragon.locked}  type="g_dragon" image={golddragon} title="Mythic Dragon"  amount={`${price.gold.g_dragon.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.gold.g_dragon.expiration} days ${price.gold.g_dragon.percentage * 100}%`} usd={`${(price.gold.g_dragon.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>

                <CreatureCard locked={price.gold.g_leviathan.locked}  type="g_leviathan" image={goldleviathan} title="Mythic Leviathan" amount={`${price.gold.g_leviathan.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} description={`${price.gold.g_leviathan.expiration} days ${price.gold.g_leviathan.percentage * 100}%`}  usd={`${(price.gold.g_leviathan.amount / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}/>
            </Row>
            </MDBTabsPane>
            </MDBTabsContent>

            <MDBTabsContent>
            <InventoryHistory/>
            </MDBTabsContent>
            
            </>
            )}

            {(tab === 'Wa' && molepricelist.length !== 0) && (
              <>
                <MDBTabs className='mb-3' pills>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleBasicClick2('tab1')} active={basicActive2 === 'tab1'}>
                  <MDBIcon fas icon='box-open' className='me-2' />Wood
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleBasicClick2('tab2')} active={basicActive2 === 'tab2'}>
                  <MDBIcon fas icon='box-open' className='me-2' />Stone
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleBasicClick2('tab3')} active={basicActive2 === 'tab3'}>
                  <MDBIcon fas icon='box-open' className='me-2' /> Steel
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>

                <MDBTabsContent>
                <MDBTabsPane open={basicActive2 === 'tab1'}>

                  <MDBRow style={{gap:'1rem'}}>
                    <MoleCard locked={molepricelist.wood.normal_mole.locked} type="normal_mole" image={wood1} title="Normal Mole"  amount={`${molepricelist.wood.normal_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.wood.normal_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.wood.normal_mole.expiration} days ${molepricelist.wood.normal_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.wood.bucket_mole.locked} type="bucket_mole" image={wood2} title="Bucket Mole"  amount={`${molepricelist.wood.bucket_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.wood.bucket_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.wood.bucket_mole.expiration} days ${molepricelist.wood.bucket_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.wood.cone_mole.locked} type="cone_mole" image={wood3} title="Cone Mole"  amount={`${molepricelist.wood.cone_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.wood.cone_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.wood.cone_mole.expiration} days ${molepricelist.wood.cone_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.wood.construction_mole.locked} type="construction_mole" image={wood4} title="Construction Mole"  amount={`${molepricelist.wood.construction_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.wood.construction_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.wood.construction_mole.expiration} days ${molepricelist.wood.cone_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.wood.miner_mole.locked} type="miner_mole" image={wood5} title="Miner Mole"  amount={`${molepricelist.wood.miner_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.wood.miner_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.wood.miner_mole.expiration} days ${molepricelist.wood.cone_mole.percentage * 100}%`}/>

                    
                </MDBRow>

                </MDBTabsPane>
                <MDBTabsPane open={basicActive2 === 'tab2'}> 
                  <MDBRow style={{gap:'1rem'}}>
                    <MoleCard locked={molepricelist.stone.football_mole.locked} type="football_mole" image={football} title="Football Mole"  amount={`${molepricelist.stone.football_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.stone.football_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.stone.football_mole.expiration} days ${molepricelist.stone.football_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.stone.soldier_mole.locked} type="soldier_mole" image={soldier} title="Soldier Mole"  amount={`${molepricelist.stone.soldier_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.stone.soldier_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.stone.soldier_mole.expiration} days ${molepricelist.stone.soldier_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.stone.general_mole.locked} type="general_mole" image={general} title="General Mole"  amount={`${molepricelist.stone.general_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.stone.general_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.stone.general_mole.expiration} days ${molepricelist.stone.general_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.stone.explorer_mole.locked} type="explorer_mole" image={explorer} title="Explorer Mole"  amount={`${molepricelist.stone.explorer_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.stone.explorer_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.stone.explorer_mole.expiration} days ${molepricelist.stone.explorer_mole.percentage * 100}%`}/>

                    <MoleCard locked={molepricelist.stone.marching_mole.locked} type="marching_mole" image={marchingband} title="Marching Band Mole"  amount={`${molepricelist.stone.marching_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.stone.marching_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.stone.marching_mole.expiration} days ${molepricelist.stone.marching_mole.percentage * 100}%`}/>

                     
                  </MDBRow>
                </MDBTabsPane>
                <MDBTabsPane open={basicActive2 === 'tab3'}>
                 <MDBRow style={{gap:'1rem'}}>

                  <MoleCard locked={molepricelist.steel.viking_mole.locked} type="viking_mole" image={viking} title="Viking Mole"  amount={`${molepricelist.steel.viking_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.steel.viking_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.steel.viking_mole.expiration} days ${molepricelist.steel.viking_mole.percentage * 100}%`}/>

                  <MoleCard locked={molepricelist.steel.shogun_mole.locked} type="shogun_mole" image={shogun} title="Shogun Mole"  amount={`${molepricelist.steel.shogun_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.steel.shogun_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.steel.shogun_mole.expiration} days ${molepricelist.steel.shogun_mole.percentage * 100}%`}/>

                  <MoleCard locked={molepricelist.steel.samurai_mole.locked} type="samurai_mole" image={samurai} title="Samurai Mole"  amount={`${molepricelist.steel.samurai_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.steel.samurai_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.steel.samurai_mole.expiration} days ${molepricelist.steel.samurai_mole.percentage * 100}%`}/>

                  <MoleCard locked={molepricelist.steel.pharaoh_mole.locked} type="pharaoh_mole" image={paroah} title="Pharaoh Mole"  amount={`${molepricelist.steel.pharaoh_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.steel.pharaoh_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.steel.pharaoh_mole.expiration} days ${molepricelist.steel.pharaoh_mole.percentage * 100}%`}/>

                  <MoleCard locked={molepricelist.steel.king_mole.locked} type="king_mole" image={king} title="King Mole"  amount={`${molepricelist.steel.king_mole.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `} usd={`${ (molepricelist.steel.king_mole.amount / rate).toFixed(2)}`} description={`${molepricelist.steel.king_mole.expiration} days ${molepricelist.steel.king_mole.percentage * 100}%`}/>

                    
                  </MDBRow>
                </MDBTabsPane>
              </MDBTabsContent>

              <MDBTabsContent className=" mt-8">
              <MoleBuyHistory/>
              </MDBTabsContent>
              </>

            )}
          

        </div>

        </>
      )}

     
    </>
  );
}

export default Buycreature;
