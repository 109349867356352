import { 
    MDBContainer, 
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBSwitch, } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const Maintenance = () => {
    const [fullgame, setFullGame] = useState("")
    const [molefullgame, setMoleFullGame] = useState("")
    const [fightgame, setFightGame] = useState("")
    const [molegame, setMoleGame] = useState("")
    const [eventgame, setEventGame] = useState("")
    const [payoutgame, setPayoutGame] = useState("")
    const [loading, setLoading] = useState(false)


    useEffect(()=> {
        fetch(`${process.env.REACT_APP_API_URL}/maintenance/getmaintenance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                const Fullg = data.data.maintenancelist.find(e => e.type == "fullgame")
                const Fg = data.data.maintenancelist.find(e => e.type == "fightgame")
                const Eg = data.data.maintenancelist.find(e => e.type == "eventgame")
                const Po = data.data.maintenancelist.find(e => e.type == "payout")
                const molegame = data.data.maintenancelist.find(e => e.type == "molegame")
                const fullmolegame = data.data.maintenancelist.find(e => e.type == "fullmolegame")
                setFightGame(Fg.value)
                setEventGame(Eg.value)
                setFullGame(Fullg.value)
                setPayoutGame(Po.value)
                setMoleGame(molegame)
                setMoleFullGame(molefullgame)
              } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

    const reload = (() => {
          fetch(`${process.env.REACT_APP_API_URL}/maintenance/getmaintenance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                const Fullg = data.data.maintenancelist.find(e => e.type == "fullgame")
                const Fg = data.data.maintenancelist.find(e => e.type == "fightgame")
                const Eg = data.data.maintenancelist.find(e => e.type == "eventgame")
                const Po = data.data.maintenancelist.find(e => e.type == "payout")
                const molegame = data.data.maintenancelist.find(e => e.type == "molegame")
                const fullmolegame = data.data.maintenancelist.find(e => e.type == "fullmolegame")
                setFightGame(Fg.value)
                setEventGame(Eg.value)
                setFullGame(Fullg.value)
                setPayoutGame(Po.value)
                setMoleGame(molegame)
                setMoleFullGame(molefullgame)
              } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    })

    const gamemaintenance = (type, ischecked) => {
        setLoading(true)
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/maintenance/changemaintenance`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        type: type,
                        value: ischecked
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }

                    if(data.message == "success"){
                        setLoading(false)
                        Swal.fire({
                            title: `Maintenance ${ischecked == "0" ? "Off": "On"}`,
                            icon: "success",
                            text: `Maintenance ${type} is now ${ischecked == "0" ? "Off": "On"}`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }

                })
            } else {
                setLoading(false)
            }
          });
    }

    return(
        <div className="content">
            <MDBContainer>
                {/* <MDBRow>
                    <MDBCol lg={6} >
                    <MDBCard>
                    <MDBCardBody className=" group rounded-lg flex justify-between" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                        <div className=" flex flex-col gap-1">
                             <img src="/game-controller.png" width={60} className=" group-hover:scale-105 ease-in-out duration-300"/>
                            <MDBCardTitle className=" text-white">Maintenance Full Game</MDBCardTitle>
                        </div>
                       

                        <div className="d-flex justify-content-end align-items-end mb-2">
                        <MDBSwitch
                        className=" text-blue-500" 
                        disabled={loading}
                        checked={fullgame  == "1" ? true : false}  name="fullgame" 
                        id='flexSwitchCheckDefault'
                        onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                        />
                        </div>
                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>

                    <MDBCol lg={6}>
                    <MDBCard>
                    <MDBCardBody className=" group rounded-lg flex justify-between" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                     <div className=" flex flex-col gap-1">
                             <img src="/sword.png" width={60} className=" group-hover:scale-105 ease-in-out duration-300"/>
                            <MDBCardTitle className=" text-white">Maintenance Fight Game</MDBCardTitle>
                        </div>
                       

                        <div className="d-flex justify-content-end align-items-end mb-2">
                            <MDBSwitch 
                            disabled={loading}
                            checked={fightgame  == "1" ? true : false}  name="fightgame" 
                            id='flexSwitchCheckDefault'
                            onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                            />
                        </div>
                  
                        
                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>

                  
                    
                </MDBRow> */}

                 <MDBRow>
                   

                    <MDBCol lg={6}>
                    <MDBCard>
                    <MDBCardBody className=" group rounded-lg flex items-center gap-4 lg:gap-8 h-[165px]" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                     <div className=" flex flex-col gap-1">
                             <img src="/walogo.png" className=" lg:w-[200px] w-[100px] group-hover:scale-105 ease-in-out duration-300"/>
                        </div>

                        <div className=" w-full flex flex-col gap-2 items-center justify-center">
                            <div className=" w-full flex items-center justify-between bg-zinc-950/50 p-3 h-fit rounded-md">

                                <div className=" flex items-center gap-2">
                                    <img src="/game-controller.png" width={30} className=" group-hover:scale-105 ease-in-out duration-300"/>
                                    <p className=" text-xs md:text-lg font-semibold text-white">Maintenance Full Game</p>
                                </div>
                                
                                    <MDBSwitch 
                                    disabled={loading}
                                    checked={molefullgame  == "1" ? true : false}  name="fullmolegame" 
                                    id='flexSwitchCheckDefault'
                                    onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                                    />

                            </div>

                            <div className=" w-full flex items-center justify-between bg-zinc-950/50 p-3 h-fit rounded-md">
                                <div className=" flex items-center gap-2">
                                    <img src="/sword.png" width={30} className=" group-hover:scale-105 ease-in-out duration-300"/>
                                    <p className=" text-xs md:text-lg font-semibold text-white">Maintenance Fight Game</p>
                                </div>
                                    <MDBSwitch 
                                    disabled={loading}
                                    checked={molegame  == "1" ? true : false}  name="molegame" 
                                    id='flexSwitchCheckDefault'
                                    onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                                    />

                            </div>

                        </div>

          

                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>

                    <MDBCol lg={6}>
                    <MDBCard>
                    <MDBCardBody className=" group rounded-lg flex items-center h-[165px] gap-4 lg:gap-8" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                     <div className=" flex flex-col gap-1">
                             <img src="/Creature Smash Logo.png"  className=" lg:w-[200px] w-[100px] group-hover:scale-105 ease-in-out duration-300"/>
                        </div>

                        <div className=" w-full flex flex-col gap-2 items-center justify-center">
                            <div className=" w-full flex items-center justify-between bg-zinc-950/50 p-3 h-fit rounded-md">

                                <div className=" flex items-center gap-2">
                                    <img src="/game-controller.png" width={30} className=" group-hover:scale-105 ease-in-out duration-300"/>
                                    <p className=" text-xs md:text-lg font-semibold text-white">Maintenance Full Game</p>
                                </div>
                                
                                    <MDBSwitch 
                                    disabled={loading}
                                    checked={fullgame  == "1" ? true : false}  name="fullgame" 
                                    id='flexSwitchCheckDefault'
                                    onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                                    />

                            </div>

                            <div className=" w-full flex items-center justify-between bg-zinc-950/50 p-3 h-fit rounded-md">
                                <div className=" flex items-center gap-2">
                                    <img src="/sword.png" width={30} className=" group-hover:scale-105 ease-in-out duration-300"/>
                                    <p className=" text-xs md:text-lg font-semibold text-white">Maintenance Fight Game</p>
                                </div>
                                    <MDBSwitch 
                                    disabled={loading}
                                    checked={fightgame  == "1" ? true : false}  name="fightgame" 
                                    id='flexSwitchCheckDefault'
                                    onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                                    />

                            </div>

                        </div>

          

                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>

                  
                    
                </MDBRow>
                
            </MDBContainer>
        </div>
    )
}

export default Maintenance;